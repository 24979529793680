
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="admin-form">
      <div class="h4">Create Order</div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-9">
          <div class="bysell-tabs">
            <div class="buysell_tab">
              <ul class="nav nav-pills mt-3" id="pills-tab" role="tablist">
                <!-- *************************1st tab*********************** -->
                <li class="nav-item" role="presentation">
                  <button :class="`nav-link ${order_tab == 'buy' ? 'active' : ''}`" id="pills-buy-tab"
                    data-bs-toggle="pill" data-bs-target="#pills-buy" type="button" role="tab" aria-controls="pills-buy"
                    aria-selected="true" @click="order_tab = 'buy'">Buy</button>
                </li>
                <!-- *************************2nd tab *********************** -->
                <li class="nav-item" role="presentation">
                  <button :class="`nav-link ${order_tab == 'sell' ? 'active' : ''}`" id="pills-sell-tab"
                    data-bs-toggle="pill" data-bs-target="#pills-sell" type="button" role="tab"
                    aria-controls="pills-sell" aria-selected="false" @click="order_tab = 'sell'">Sell</button>
                </li>

              </ul>
            </div>

            <div class="content">
              <div class="tab-content" id="pills-tabContent">
                <!-- *************************1st tab content*********************** -->
                <div class="tab-pane fade show active" id="pills-buy" role="tabpanel" aria-labelledby="pills-buy-tab">
                  <div :class="`${order_tab}-form`">
                    <form @submit.prevent>
                      <div class="row">
                        <div class="col-12 col-md-6">
                          <div class="input-group form-feilds mb-3">
                            <span class="input-group-text" id="basic-addon3">Currency</span>
                            <select class="form-select shadow-none" v-model="currency" @change="currency_error = ''">
                              <option selected>{{ order_tab == 'buy'||'sell' ? 'USDT' : 'USD' }} </option>
                            </select>
                          </div>
                           
                          <span   style="color: red; font-weight: bold" v-html="currency_error"></span>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="input-group form-feilds mb-3">
                            <span class="input-group-text" id="basic-addon3">With
                              Currency</span>
                            <select class="form-select shadow-none" v-model="with_currency"
                              @change="with_currency_error = ''">
                              <option selected>{{ order_tab == 'buy'||'sell' ? 'USD' : 'USDT' }}</option>
                            </select>
                          </div>
                          <span style="color: red; font-weight: bold"  v-html="with_currency_error"></span>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="input-group form-feilds mb-3">
                            <span class="input-group-text" id="basic-addon3">At
                              Price</span>
                            <input type="text" class="form-control shadow-none" id="basic-url"
                              aria-describedby="basic-addon3" v-model="at_price" @input="at_price_error = ''"
                              onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))">
                          </div>
                          <span style="color: red; font-weight: bold"  v-html="at_price_error"></span>
                        </div>
                        <div class="col-12 col-md-6" v-if="order_tab == 'sell'">
                          <div class="input-group mb-3">
                            <label class="input-group-text w-25">Payment Type</label> 
                            <multiselect @change="payment_type_error = ''" v-model="payment_type" :show-labels="false"
                              :options="payTypes" label="Payment Type" track-by="Payment Type" class="w-75" multiple>
                            </multiselect>
                          </div>
                          <span style="color: red; font-weight: bold" v-html="payment_type_error"></span>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="input-group form-feilds mb-3">
                            <span class="input-group-text" id="basic-addon3">Min
                              Quantity</span>
                            <input type="text" class="form-control shadow-none" id="basic-url"
                              aria-describedby="basic-addon3" v-model="min_quantity" @input="min_error = ''"
                              onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))">
                          </div>
                          <span style="color: red; font-weight: bold"  v-html="min_error"></span>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="input-group form-feilds mb-3">
                            <span class="input-group-text" id="basic-addon3">Max
                              Quantity</span>
                            <input type="text" class="form-control shadow-none" id="basic-url"
                              aria-describedby="basic-addon3" v-model="max_quantity" @input="max_error = ''"
                              onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))">
                          </div>
                          <span style="color: red; font-weight: bold" v-html="max_error"></span>
                        </div>


                        <div class="col-12" v-if="!loader">
                          <div :class="`${order_tab}-btn  text-center`">
                            <button class="" type="submit" @click="fsubmit">{{ order_tab }} coin</button>
                          </div>
                        </div>
                        <div class="col-12" :class="`${order_tab}-btn  text-center lodder`" v-else>
                          <button>
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- *************************2nd tab content*********************** -->

              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="row">

      <!--col-xl-12 col-lg-12 col-md-12-->

      <DataTable :value="customers" :lazy="true" :paginator="true" class="p-datatable-customers" :rows="per_page"
        :totalRecords="totalRows" @page="onPage($event)" @sort="onSort($event)" dataKey="id" :rowHover="true"
        :filters="filters" :loading="loading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="filterPage"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
        <!--:rowsPerPageOptions="[10, 25, 50]"-->
        <template #header>
          <div class="table-header">
            List of Orders
            <!-- <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <Dropdown v-model="filters.duration" :options="duration"  @change="topFilter($event.value,'duration')" placeholder="Select a type" class="p-column-filter" :showClear="true">
                <template #option="slotProps">
                    <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                </template>
            </Dropdown>
               
            </span> -->
          </div>
        </template>
        <template #empty> No record found. </template>
        <template #loading> Loading data. Please wait. </template>
        <!-- <Column selectionMode="multiple" headerStyle="width: 3em"></Column> -->
        <Column field="name" header="Name" :sortable="true" filterMatchMode="equals">
          <template #body="slotProps">
            <span class="p-column-title">Name</span>

            {{ slotProps.data.user.name }}
          </template>
          <template #filter>
            <InputText type="text" v-model="filters.name" @keydown.enter="topFilter(filters.name, 'name')"
              class="p-column-filter" placeholder="Search by name" />
          </template>
        </Column>
        <Column field="order_type" header="Type" :sortable="true" filterMatchMode="equals">
          <template #body="slotProps">
            <span class="p-column-title">Type</span>
            <span :class="'customer-badge name-' + slotProps.data.order_type">{{
                slotProps.data.order_type
            }}</span>
          </template>
          <template #filter>
            <!-- <InputText type="text" v-model="filters.status" class="p-column-filter"  @keydown.enter="onFilter($event)" placeholder="Search by status"/> -->
            <Dropdown v-model="filters.order_type" @change="topFilter($event.value, 'order_type')" :options="order_type"
              placeholder="Select a type" class="p-column-filter" :showClear="true">
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                    slotProps.option
                }}</span>
              </template>
            </Dropdown>
          </template>>
        </Column>

        <Column header="Quantity" :sortable="true" sortField="quantity" filterField="quantity"
          filterMatchMode="contains">
          <template #body="slotProps">
            <span class="p-column-title">Quantity</span>

            {{ slotProps.data.max_quantity }}
          </template>
          <template #filter>
            <InputText type="text" v-model="filters.max_quantity"
              @keydown.enter="topFilter(filters.max_quantity, 'quantity')" class="p-column-filter"
              placeholder="Search by quantity" />
          </template>
        </Column>
        <Column header="At Price" :sortable="true" sortField="price" filterField="price" filterMatchMode="contains">
          <template #body="slotProps">
            <span class="p-column-title">At Price</span>

            {{ slotProps.data.at_price }}
          </template>
          <template #filter>
            <InputText type="text" v-model="filters.at_price" @keydown.enter="topFilter(filters.at_price, 'price')"
              class="p-column-filter" placeholder="Search by price" />
          </template>
        </Column>
        <Column header="Total" :sortable="true" sortField="total_price" filterField="total_price"
          filterMatchMode="contains">
          <template #body="slotProps">
            <span class="p-column-title">total_price</span>

            {{ slotProps.data.total }}
          </template>
          <template #filter>
            <InputText type="text" v-model="filters.total" @keydown.enter="topFilter(filters.total, 'total_price')"
              class="p-column-filter" placeholder="Search by total_price" />
          </template>
        </Column>

        <Column header="Date" :sortable="true" sortField="created_at" filterField="created_at"
          filterMatchMode="contains">
          <template #body="slotProps">
            <span class="p-column-title">Created At</span>
            <span :class="'customer-badge status-' + slotProps.data.created_at">{{
                new Date(slotProps.data.created_at).toLocaleDateString()
            }}</span>
          </template>
          <template #filter>
            <Calendar v-model="filters.date" :showButtonBar="true" dateFormat="yy-mm-dd" class="p-column-filter"
              @date-select="topFilter(filters.date, 'date')" placeholder="Search by date" />
          </template>
        </Column>

        <Column field="status" header="Status" :sortable="true" filterMatchMode="equals">
          <template #body="slotProps">
            <span class="p-column-title">Status</span>
            <span :class="'customer-badge status-' + slotProps.data.status">{{ slotProps.data.status }}</span>
          </template>
          <template #filter>
            <!-- <InputText type="text" v-model="filters.status" class="p-column-filter"  @keydown.enter="onFilter($event)" placeholder="Search by status"/> -->
            <Dropdown v-model="filters.status" @change="topFilter($event.value, 'status')" :options="cryptoStatus"
              optionValue="key" optionLabel="value" placeholder="Select a Status" class="p-column-filter"
              :showClear="true">
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                    slotProps.option.value
                }}</span>
              </template>
            </Dropdown>
          </template>>
        </Column>
      </DataTable>

    </div>

  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import ApiClass from "../../../api/api";
import filterPage from "../../../jsonFiles/filterPage.json";
import PageHeader from "@/components/page-header";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
// import ColumnGroup from 'primevue/columngroup';
import InputText from "primevue/inputtext";
import Multiselect from 'primevue/multiselect';
import Dropdown from "primevue/dropdown";

import Calendar from "primevue/calendar";
// import ProgressBar from 'primevue/progressbar';

export default {
  components: {
    Layout,
    PageHeader,
    DataTable,
    Dropdown,
    Calendar,
    Column,
    InputText,
    Multiselect,

  },
  watch: {
    modelvalue(v) {
      this.BuyerSellerData[0].series = [parseFloat(v.buyer_total).toFixed(2)];
      this.BuyerSellerData[1].series = [parseFloat(v.seller_total).toFixed(2)];
    },
  },
  data() {
    return {
      filterPage: filterPage.allpages,
      totalRows: 1,
      per_page: 10,
      page: 1,
      title: "P2P",
      items: [
        {
          text: "Manage P2P",
          href: "#",
        },
        {
          text: "P2P",
          active: true,
        },
      ],
      filters: {
        order_type: null,
        status: null,
        quantity: null,
        price: null,
        total_price: null,
        coin: null,
        date: null,
      },
      filter: "",
      customers: [],
      selectedCustomers: null,
      loading: true,
      crypto: [],
      total_qty: 0,

      cryptoStatus: [{ key: "processing", value: "processing" }, { key: "placed", value: "placed" },
      { key: "completed", value: "completed" },
      { key: "cancelled", value: "cancelled" }, { key: "partially_completed", value: "partially_completed" }],
      duration: ["day", "week", "month"],
      order_type: ["buy", "sell"],
      // currency: [],

      allCurrency: [],
      modelvalue: [],
      order_tab: 'buy',
      symbol: "BTC",
      payTypes: [],
      currency: '',
      with_currency: '',
      at_price: '',
      max_quantity: '',
      min_quantity: '',
      payment_type: '',
      currency_error: '',
      with_currency_error: '',
      payment_type_error: '',
      at_price_error: '',
      max_error: '',
      min_error: '',
      loader: false
    };
  },
  created() { },
  async mounted() {
    this.client();
    this.paymentTypes()

  },
  methods: {
    async client() {
      let response = await ApiClass.getNodeRequest("P2P/P2POrder/allorderlist?user_id=1&per_page=" + this.per_page + "&page=" + this.page + "&" + this.filter, true);
      // let response = await ApiClass.getNodeRequest("P2P/cryptotransfer/order-list?per_page=" + this.per_page + "&page=" + this.page + "&" + this.filter, true);
      console.log("response.data.data ==", response);
      if (response.data.status_code == 1) {
        this.loading = false;
        this.customers = response.data.data.data;
        // console.log("rahul", this.customers);
        this.currentPage = response.data.data.current_page;
        this.totalRows = response.data.data.total;
        this.total_qty = response.data.data.total_qty;
      }

      // "admin/p2p/get_orders?per_page=" +
      //   this.per_page +
      //   "&page=" +
      //   this.page +
      //   "&" +
      //   this.filter
      // ).then((res) => {
      //   if (res.data.status_code == 1) {
      //     this.loading = false;
      //     this.customers = res.data.data.data;
      //     console.log("rahul" ,  this.customers);
      //     this.currentPage = res.data.data.current_page;
      //     this.totalRows = res.data.data.total;
      //     this.total_qty = res.data.data.total_qty;
      //     // console.log("thiss", this.customers);
      //   }
      // });
    },



    onPage(event) {
      if (this.page != event.page + 1) {
        this.loading = !this.loading;
        this.page = event.page + 1;
      }
      if (this.per_page != event.rows) {
        this.loading = !this.loading;
        this.per_page = event.rows;
      }
      this.client();
    },
    onSort(e) {

      for (let elKey in this.filters) {
        //console.log(elKey);
        this.filters[elKey] = null;
      }

      this.loading = !this.loading;
      var sortby = e.sortOrder == 1 ? "asc" : "desc";
      this.filter = "sortby=" + sortby + "&sortbyname=" + e.sortField;

      this.client();
    },
    onFilter() { },

    topFilter(columnVal, columnName) {

      if (columnName == "date") {
        columnVal = columnVal.toLocaleDateString("es-US").split("T")[0];
        columnVal = columnVal.split("/").reverse().join("-");
        // columnVal = columnVal.split("/").join("-");
      }
      this.loading = !this.loading;

      this.$set(this.filters, columnName, columnVal);

      var x = this.filters;
      console.log("value of x", x)
      const resulted = JSON.parse(JSON.stringify(x), (key, value) =>
        value === null || value === "" ? undefined : value
      );

      let query = new URLSearchParams(resulted).toString();

      this.filter = query;

      this.client();
    },
    async paymentTypes() {
      let response = await ApiClass.getNodeRequest("P2P/P2POrder/paymenttype");
      if (response.data.status_code == 1) {
        this.payTypes = Object.keys(response.data.data)
      }
    },
    async fsubmit() {
      if (!this.currency) this.currency_error = "Currency is required"
      if (!this.with_currency) this.with_currency_error = "With currency is required"
      if (!this.at_price) this.at_price_error = "Price is required"
      if (!this.payment_type && this.order_tab == 'sell') this.payment_type_error = "Payment type is required"
      if (!this.min_quantity) this.min_error = "Min quantity is required"
      if (!this.max_quantity) this.max_error = "Max quantity is required"
      if (this.currency_error || this.with_currency_error || this.at_price_error || this.payment_type_error || this.min_error || this.max_error) return

      this.loader = true;
      let form_data = {
        currency: this.currency,
        with_currency: this.with_currency,
        at_price: this.at_price,
        min_quantity: this.min_quantity,
        max_quantity: this.max_quantity,
        order_type: this.order_tab
      };
      form_data.payment_type= this.order_tab == "sell" ? this.payment_type.join(','):"";
      let response = await ApiClass.postNodeRequest("P2P/P2POrder/create", true, form_data);
      if (response?.data) {
        this.loader = false;
        this.currency = this.with_currency = this.at_price = this.payment_type = this.min_quantity = this.max_quantity = ""
        this.client();
        this.$swal({
          position: 'top-end',
          icon: response.data.status_code == 1 ? 'success' : 'error',
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500
        })
      }
    }
  },
};
</script>
  <style lang="scss">
  .p-paginator {
    .p-paginator-current {
      margin-left: auto;
    }
  }
  
  .p-progressbar {
    height: 0.5rem;
    background-color: #d8dadc;
  
    .p-progressbar-value {
      background-color: #00acad;
    }
  }
  
  .table-header {
    display: flex;
    justify-content: space-between;
  }
  
  .p-datepicker {
    min-width: 25rem;
  
    td {
      font-weight: 400;
    }
  }
  
  .p-datatable.p-datatable-customers {
    .p-datatable-header {
      padding: 1rem;
      text-align: left;
      font-size: 1.5rem;
    }
  
    .p-paginator {
      padding: 1rem;
    }
  
    .p-datatable-thead>tr>th {
      text-align: left;
    }
  
    .p-datatable-tbody>tr>td {
      cursor: auto;
    }
  
    .p-dropdown-label:not(.p-placeholder) {
      text-transform: uppercase;
    }
  }
  
  .p-calendar .p-datepicker {
    width: 350px;
  }
  
  .p-inputtext {
    width: 100%;
  }
  
  /* Responsive */
  .p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
    display: none;
  }
  
  @media screen and (max-width: 64em) {
    .p-datatable {
      &.p-datatable-customers {
  
        .p-datatable-thead>tr>th,
        .p-datatable-tfoot>tr>td {
          display: none !important;
        }
  
        .p-datatable-tbody>tr>td {
          text-align: left;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;
  
          .p-column-title {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4rem 1rem -0.4rem -0.4rem;
            font-weight: bold;
          }
        }
      }
    }
  }
  
  .admin-form {
    margin-bottom: 29px;
  
  }
  
  .buy-form,
  .sell-form {
    margin: 0px 0px 29px 0px;
  }
  
  /***************nav-tabs*****************/
  .bysell-tabs {
    background-color: #fff;
    padding: 10px 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  
  .nav-pills {
    background-color: #f8f8fb;
    border-radius: 5px;
  }
  
  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #556ee6;
  }
  
  .nav-pills .nav-link {
    color: #495057;
    font-weight: 500;
    font-size: 13px;
    padding: 0.5rem 1rem;
  }
  
  /*********************buysell form**********************************/
  .input-group-text {
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    color: #495057;
    background-color: #eff2f7;
    border: 1px solid #ced4da;
  }
  
  .buy-btn button {
    padding: 0.47rem 0.75rem;
    background-color: #1b9367;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
  .lodder button {
    padding: 0px 1.50rem;
  }
  
  .sell-btn button {
    padding: 0.47rem 0.75rem;
    background-color: #ff4b4b;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
  .lodder button {
    padding: 0px 1.45rem;
  }
  
  .main-content .content {
    padding: 0 15px 10px 15px;
    margin-top: 22px;
  }
  
  .form-control {
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    color: #495057;
  }
  
  .form-control:focus {
    border-color: #ced4da;
    outline: 0;
  }
  
  
  
  .form-select:focus {
    outline: none;
    border-color: #ced4da;
  }
  
  .form-select {
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    color: #495057;
  }
  </style>



